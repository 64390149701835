<template>
    <div>
        <div v-if="$acl.check('manager')" class="vx-row">
            <vx-card>
                <h5 style="margin-bottom:9px">Ventas Generales</h5>
                <span>Reporte basado en las fechas de <strong>venta</strong></span>
                <!-- TABLE ACTION ROW -->
                <div class="flex flex-wrap justify-between items-center">
                    <!-- ITEMS PER PAGE -->
                    <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
                    <vs-dropdown vs-trigger-click class="cursor-pointer">
                        <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ sales.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : sales.length }} de {{ sales.length }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                        </div>
                        <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                        <vs-dropdown-menu>
                        <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                            <span>20</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                            <span>50</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                            <span>100</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                            <span>150</span>
                        </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                    </div>

                    <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
                    <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
                        <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
                        <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
                        <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
                        <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
                        <vs-input
                            class="mb-4 md:mb-0 mr-4"
                            v-model="searchQuery"
                            @input="updateSearchQuery"
                            placeholder="Buscar..."/>
                        <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
                    </div>
                </div>
                <ag-grid-vue
                    ref="agGridTable"
                    :gridOptions="gridOptions"
                    class="ag-theme-material w-100 my-4 ag-grid-table"
                    :columnDefs="columnDefs"
                    :defaultColDef="defaultColDef"
                    :rowData="sales"
                    @first-data-rendered="onFirstDataRendered"
                    rowSelection="multiple"
                    colResizeDefault="shift"
                    :animateRows="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :suppressPaginationPanel="true"
                    :enableRtl="$vs.rtl"
                    :modules="modules">
                </ag-grid-vue>
                <ag-grid-vue style="height: 60px; flex: none;"
                    class="ag-theme-material w-100 ag-grid-table total"
                    :gridOptions="bottomGridOptions"
                    :headerHeight="0"
                    :columnDefs="columnDefs"
                    :floatingFiltersHeight="0"
                    :rowData="salesFooter"
                    :modules="modules">
                </ag-grid-vue>
                <vs-pagination
                    :total="totalPages"
                    :max="maxPageNumbers"
                    v-model="currentPage" />
            </vx-card>
        </div>
        <div v-if="$acl.check('admin') || $acl.check('superadmin')" class="vx-row">
            <vx-card>
                <h5 style="margin-bottom:9px">Resumen de Ventas</h5>
                <span>Reporte basado en las fechas de <strong>venta</strong></span>
                <!-- TABLE ACTION ROW -->
                <div class="flex flex-wrap justify-between items-center">

                    <!-- ITEMS PER PAGE -->
                    <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
                    <vs-dropdown vs-trigger-click class="cursor-pointer">
                        <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ currentPageSummary * paginationPageSizeSummary - (paginationPageSizeSummary - 1) }} - {{ summary.length - currentPageSummary * paginationPageSizeSummary > 0 ? currentPageSummary * paginationPageSizeSummary : summary.length }} de {{ summary.length }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                        </div>
                        <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                        <vs-dropdown-menu>

                        <vs-dropdown-item @click="gridApiSummary.paginationSetPageSizeSummary(20)">
                            <span>20</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="gridApiSummary.paginationSetPageSizeSummary(50)">
                            <span>50</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="gridApiSummary.paginationSetPageSizeSummary(100)">
                            <span>100</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="gridApiSummary.paginationSetPageSizeSummary(150)">
                            <span>150</span>
                        </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                    </div>

                    <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
                     <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
                        <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
                        <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
                        <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
                        <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
                        <vs-input
                            class="mb-4 md:mb-0 mr-4"
                            v-model="searchQuery"
                            @input="updateSearchQuery"
                            placeholder="Buscar..."/>
                        <vs-button class="mb-4 md:mb-0" @click="gridApiSummary.exportDataAsCsv()">Exportar</vs-button>
                    </div>
                </div>
                <ag-grid-vue
                    ref="agGridTable"
                    :gridOptions="gridOptionsSummary"
                    class="ag-theme-material w-100 my-4 ag-grid-table"
                    :columnDefs="columnSummary"
                    :defaultColDef="defaultColDef"
                    :rowData="summary"
                    @first-data-rendered="onFirstDataRendered"
                    rowSelection="multiple"
                    colResizeDefault="shift"
                    :animateRows="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSizeSummary"
                    :suppressPaginationPanel="true"
                    :enableRtl="$vs.rtl"
                    :modules="modules">
                </ag-grid-vue>
                <ag-grid-vue style="height: 60px; flex: none;"
                    class="ag-theme-material w-100 ag-grid-table total"
                    :gridOptions="bottomGridOptionsSummary"
                    :headerHeight="0"
                    :floatingFiltersHeight="0"
                    :columnDefs="columnSummary"
                    :rowData="summaryFooter"
                    :modules="modules">
                </ag-grid-vue>
                <vs-pagination
                    :total="totalPagesSummary"
                    :max="maxPageNumbersSummary"
                    v-model="currentPageSummary" />
            </vx-card>
        </div>
    </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import { AgGridVue } from "@ag-grid-community/vue"
import reportModule from '@/store/report/moduleReport.js'
import DatePicker from "vuejs-datepicker";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import * as lang from 'vuejs-datepicker/src/locale';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

export default {
    data() {
        return {
            languages: lang,
            from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),

            //Table
            searchQuery: '',
            gridOptions: {
                alignedGrids: [],
                suppressHorizontalScroll: true
            },
            gridOptionsSummary:{
                alignedGrids: [],
                suppressHorizontalScroll: true
            },
            modules: AllCommunityModules,

            maxPageNumbers: 6,
            maxPageNumbersSummary: 8,
            gridApi: null,
            gridApiSummary:null,
            defaultColDef: {
                sortable: true,
                editable: false,
                resizable: true,
                suppressMenu: true
            },
            columnDefs: [
                {
                headerName: 'Id Venta',
                field: 'id',
                width: 120,
                filter: true,
                sort: 'desc',
                },
                {
                headerName: 'Fecha',
                field: 'fecha',
                width: 160,
                filter: true,
                comparator: this.myDateComparator
                },
                {
                headerName: 'Tipo de Venta',
                field: 'sale_type_description',
                filter: true,
                floatingFilter: true,
                width: 220
                },
                {
                headerName: 'Total',
                field: 'sale_total',
                filter: false,
                width: 160,
                valueFormatter: params => this.$options.filters.currencySign(params.data.sale_total, '$ '),
                },
                {
                headerName: 'Vendedor',
                field: 'name_seller',
                filter: true,
                floatingFilter: true,
                width: 320
                },
                {
                headerName: 'Comprador',
                field: 'name_buyer',
                filter: true,
                floatingFilter: true,
                width: 320
                },
            ],
            sales: [],
            salesFooter: [],
            columnSummary:  [
                {
                headerName: 'ID',
                field: 'id_institution',
                width: 110,
                filter: true,
                floatingFilter: true
                },
                {
                headerName: 'Cafetería',
                field: 'name_institution',
                width: 300,
                filter: true,
                floatingFilter: true
                },
                {
                headerName: 'Total Ventas',
                field: 'sale_total',
                width: 160,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.sale_total, '$ '),
                cellStyle: {color: 'blue', fontWeight: '600'}
                },
                {
                headerName: 'Tiptap',
                field: 'total_tiptap',
                width: 160,
                filter: false,
                valueFormatter: params => this.$options.filters.currencySign(params.data.total_tiptap, '$ '),
                },
                {
                headerName: 'Tarjeta',
                field: 'total_tarjeta',
                width: 160,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.total_tarjeta, '$ '),
                },
                {
                headerName: 'Efectivo',
                field: 'total_efectivo',
                width: 300,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.total_efectivo, '$ '),
                },
                   {
                headerName: 'Cortesía',
                field: 'total_cortesia',
                width: 160,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.total_cortesia, '$ '),
                },
                {
                headerName: 'Recarga',
                field: 'total_recargas',
                width: 140,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.total_recargas, '$ '),
                },
            ],
            summary:[],
            summaryFooter: [],
            bottomGridOptions: {
                alignedGrids: [],
                defaultColDef: {
                    editable: false,
                    sortable: true,
                    resizable: true,
                    filter: true,
                    flex: 1,
                    // minWidth: 100,
                    floatingFiltersHeight: 0
                }
            },
            bottomGridOptionsSummary: {
                alignedGrids: [],
                defaultColDef: {
                    editable: false,
                    sortable: true,
                    resizable: true,
                    filter: true,
                    flex: 1,
                    // minWidth: 100,
                    floatingFiltersHeight: 0
                }
            },
        }
    },
    components : {
        DatePicker,
        VuePerfectScrollbar,
        VueApexCharts,
        AgGridVue
    },

    created() {
        //Modules
        if(!reportModule.isRegistered) {
            this.$store.registerModule('reportModule', reportModule)
            reportModule.isRegistered = true
        }

        if(this.$acl.check('manager'))
            this.loadSaleTable();

        if(this.$acl.check('admin') || this.$acl.check('superadmin'))
            this.loadSummaryTable();
    },

    methods: {
        isDate(val) {
            return val instanceof Date && !isNaN(val.valueOf())
        },
        loadTotalSalesTable() {
            this.salesFooter = [];
            var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};

            this.$store.dispatch("reportModule/getSales",{isAdmin:false,id:this.$store.state.AppActiveUser.institution.id, dates:dates})
                .then(response => {
                    if(response.data.sales.length > 0) {
                        let s = JSON.parse(JSON.stringify(response.data.sales));
                        let s_footer = {};
                        s_footer.id = "Total";
                        s_footer.sale_total = 0;

                        s.forEach(element => {
                            element.fecha = new Date(element.fecha).toLocaleDateString('es-AR', this.$localeDateOptions)
                            response.data.account.forEach((account) => {
                                if(element.id_seller == account.id_account){
                                    element.name_seller = account.name + " " + account.last_name;
                                }
                                if(element.id_buyer== account.id_account){
                                    element.name_buyer = account.name + " " + account.last_name;
                                }
                            })
                            s_footer.sale_total += element.sale_total;
                        });

                        this.salesFooter = [s_footer];
                        this.sales = s;
                    }
                })
                .catch(error => {console.log(error)})
        },
        loadSaleTable() {
            if(this.$acl.check('admin') || this.$acl.check('superadmin'))
                this.loadSummaryTable()
            else
                this.loadTotalSalesTable()
        },
        loadSummaryTable() {
            this.summary = []
            this.summaryFooter = [];

            var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};

            this.$store.dispatch("reportModule/getSummary", dates)
                .then(response => {
                    if(response.data && response.data.length > 0) {
                        let s = response.data;
                        let s_footer = {};
                        s_footer.id_institution = "Total";
                        s_footer.sale_total = 0;
                        s_footer.total_tiptap = 0;
                        s_footer.total_tarjeta = 0;
                        s_footer.total_efectivo = 0;
                        s_footer.total_cortesia = 0;
                        s_footer.total_recargas = 0;

                        s.forEach(element => {
                            element.fecha = new Date(element.fecha).toLocaleDateString('es-AR', this.$localeDateOptions)
                            s_footer.sale_total += element.sale_total !== null ? element.sale_total : 0;
                            s_footer.total_tiptap += element.total_tiptap !== null ? element.total_tiptap : 0;
                            s_footer.total_tarjeta += element.total_tarjeta !== null ? element.total_tarjeta : 0;
                            s_footer.total_efectivo += element.total_efectivo !== null ? element.total_efectivo : 0;
                            s_footer.total_cortesia += element.total_cortesia !== null ? element.total_cortesia : 0;
                            s_footer.total_recargas += element.total_recargas !== null ? element.total_recargas : 0;
                        });

                        this.summaryFooter = [s_footer]
                        this.summary = s
                    }
                })
                .catch(error => {console.log(error)})
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val);
            this.gridOptionsSummary.setQuickFilter(val);
        },
        myDateComparator(date1, date2) {
            var date1Number = this.monthToComparableNumber(date1);
            var date2Number = this.monthToComparableNumber(date2);
            if (date1Number === null && date2Number === null) {
                return 0;
            }
            if (date1Number === null) {
                return -1;
            }
            if (date2Number === null) {
                return 1;
            }
            return date1Number - date2Number;
        },
        monthToComparableNumber(date) {
            if (date === undefined || date === null || date.length !== 10) {
                return null;
            }
            var yearNumber = date.substring(6, 10);
            var monthNumber = date.substring(3, 5);
            var dayNumber = date.substring(0, 2);
            var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
            return result;
        },
        onFirstDataRendered: function() {
            this.gridColumnApi.autoSizeAllColumns();
        }
    },

    watch: {
        from: function () {
            this.loadSaleTable();
        },
        to: function () {
            this.loadSaleTable();
        },
        '$store.state.windowWidth'(val) {
            if(val <= 576) {
                this.maxPageNumbers = 6;
                this.maxPageNumbersSummary = 8;
            }
        }
    },
    computed: {
        paginationPageSize() {
            if(this.gridApi) return this.gridApi.paginationGetPageSize()
            else return 50
        },
        paginationPageSizeSummary(){
            if(this.gridApiSummary) return this.gridApiSummary.paginationGetPageSize()
            else return 50
        },
        totalPages() {
            if(this.gridApi) return this.gridApi.paginationGetTotalPages()
            else return 0
        },
        totalPagesSummary(){
            if(this.gridApiSummary) return this.gridApiSummary.paginationGetTotalPages()
                else return 0
        },
        currentPage: {
            get() {
                if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
                else return 1
            },

            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
            currentPageSummary: {
            get() {
                if(this.gridApiSummary) return this.gridApiSummary.paginationGetCurrentPage() + 1
                else return 1
            },

            set(val) {
                this.gridApiSummary.paginationGoToPage(val - 1)
            }
        }
    },
    beforeMount() {
        if(this.$acl.check('admin') || this.$acl.check('superadmin')) {
            this.gridOptionsSummary.alignedGrids.push(this.bottomGridOptionsSummary);
            this.bottomGridOptionsSummary.alignedGrids.push(this.gridOptionsSummary);
        } else {
            this.gridOptions.alignedGrids.push(this.bottomGridOptions);
            this.bottomGridOptions.alignedGrids.push(this.gridOptions);
        }


    },
    mounted() {
        this.gridApi = this.gridOptions.api
        this.gridApiSummary = this.gridOptionsSummary.api
        this.gridColumnApi = (this.$acl.check('admin') || this.$acl.check('superadmin')) ? this.gridOptionsSummary.columnApi : this.gridOptions.columnApi;

        if(this.$vs.rtl) {
            const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
            header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
        }
    }
}

</script>

<style>

    .ag-cell{
        text-transform: capitalize;
    }
    .ag-grid-table.total .ag-row {
        /* background-color: #7c71f138; */
        font-weight: 700;
    }
    .ag-grid-table.total .ag-header {
        border: none;
    }
    /* .ag-grid-table.total .ag-row-first {
        border-top-width: 1px;
    } */
</style>
